import React from 'react'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import AdSense from 'react-adsense'

const Ad: React.FC = () => (
  <div className="column is-three-fifths is-12-mobile is-offset-one-fifth">
    <AdSense.Google
      client="ca-pub-1547867600878186"
      slot="2057424181"
      style={{ display: 'block' }}
      format="auto"
      responsive="true"
    />
  </div>
)
export default Ad
