import React from 'react'
import {
  FacebookIcon,
  TwitterIcon,
  LineShareButton,
  LineIcon,
  PocketShareButton,
  PocketIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share'
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'url-... Remove this comment to see the full error message
import urljoin from 'url-join'

// interface
interface ShareProps {
  postNode: {
    [key: string]: any
  }
  siteUrl: string
}

const Share: React.FC<ShareProps> = ({ postNode, siteUrl }) => {
  const post = postNode.frontmatter
  const url = urljoin(siteUrl, postNode.fields.slug)
  const iconSize = 45

  return (
    <div className="flex flex-row justify-center items-center gap-x-2 sm:gap-x-4">
      <div className="flex">
        <a
          className="twitter-share-button"
          href={`https://twitter.com/intent/tweet?text=${post.title}&url=${url}`}
        >
          <TwitterIcon round size={iconSize} />
        </a>
      </div>

      <div className="flex">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
          className="fb-xfbml-parse-ignore"
        >
          <FacebookIcon round size={iconSize} />
        </a>
      </div>

      <div className="flex">
        <LineShareButton url={url} title={post.title}>
          <LineIcon round size={iconSize} />
        </LineShareButton>
      </div>

      <div className="flex">
        <PocketShareButton url={url} title={post.title}>
          <PocketIcon round size={iconSize} />
        </PocketShareButton>
      </div>

      <div className="flex">
        <EmailShareButton url={url} title={post.title}>
          <EmailIcon round size={iconSize} />
        </EmailShareButton>
      </div>
    </div>
  )
}

export default Share
