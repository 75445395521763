import React from 'react'
import { shuffle, kebabCase } from 'lodash'
import { graphql, Link } from 'gatsby'
import { FaTag } from 'react-icons/fa'

import DefaultLayout from '../components/Layout/default'
import Share from '../components/Share'
import Content, { HTMLContent } from '../components/Content'
import Ad from '../components/Ad'
import CardGrid from '../components/Article/CardGrid'
import SEO from '../components/SEO'

// type
import { BlogPostByIdQuery } from '../../types/graphql-types'
interface BlogPostTemplateProps {
  data: BlogPostByIdQuery
}

const BlogPostTemplate: React.FC<BlogPostTemplateProps> = ({ data }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { site, allMarkdownRemark, markdownRemark, tags } = data

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { frontmatter, fields, html } = markdownRemark
  const { slug } = fields
  const { title, description, featuredimage } = frontmatter
  const { publicURL } = featuredimage

  // おすすめ記事
  // @TODO 自分の記事自身は消す
  const shuffleEdges = shuffle(allMarkdownRemark.edges)
  const recommendEdges = shuffleEdges.slice(0, 3)

  const PostContent = HTMLContent || Content

  return (
    <DefaultLayout title="記事">
      <SEO
        title={title}
        description={description}
        slug={slug}
        featuredImagePath={publicURL}
        keywords={[
          '経済',
          'ブログ',
          'Notion',
          '投資',
          'プログラミング',
          'デザイン',
        ]}
      />

      <div className="container mx-auto max-w-4xl flex flex-col">
        <section className="flex flex-col my-10 mx-5">
          <h1 className="text-xl sm:text-3xl mt-5 mx-5 leading-snug">
            <p
              className="inline hover:underline py-1 px-4 m-1 text-tintBlack leading-10"
              style={{
                background: 'linear-gradient(transparent 70%, pink 70%)',
              }}
            >
              {title}
            </p>
          </h1>

          <p className="text-md sm:text-xl leading-relaxed my-10 mx-5">
            {description}
          </p>

          <PostContent content={html} className="markdown" />
          {tags && tags.length ? (
            <div style={{ marginTop: '4rem' }}>
              <h4>Tags</h4>

              <ul className="taglist">
                {tags.map((tag: any) => (
                  <li key={`${tag}tag`}>
                    <Link to={`/tags/${kebabCase(tag)}/`}>
                      <span className="iconTag">
                        <FaTag />
                      </span>
                      &nbsp;{tag}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </section>

        <Ad />

        <Share
          siteUrl={site?.siteMetadata?.siteUrl || 'ごんじっちブログ'}
          postNode={markdownRemark || {}}
        />

        <div className="mx-5 mt-10 sm:mt-32">
          <CardGrid articleEdges={recommendEdges} />
        </div>
      </div>
    </DefaultLayout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      limit: 1000
    ) {
      edges {
        node {
          excerpt(pruneLength: 100)
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            tags
            templateKey
            date(formatString: "MMMM DD, YYYY")
            featuredpost
            featuredimage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        featuredimage {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        tags
      }
    }
  }
`
