import React from 'react'

export const HTMLContent = ({ content, className }: any) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
)

type ContentProps = {
  content?: React.ReactNode
  className?: string
}

const Content = ({ content, className }: ContentProps) => (
  <div className={className}>{content}</div>
)

// HTMLContent.propTypes = (Content as ContentProps).propTypes

export default Content
